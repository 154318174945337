<template>
  <div class="filters">
    <div class="filter_block">
      <custom-input
          :delay="400"
          label="Название"
          name="title"
          v-model="searchParams.titleField"
          clear
      />
      <custom-input
          :delay="400"
          label="Имя"
          name="name"
          v-model="searchParams.userField"
          clear
      />
      <custom-input
          :delay="400"
          label="Город"
          name="city"
          v-model="searchParams.cityField"
          clear
      />
    </div>
    <div class="search_btn search__clear-btn">
      <button @click="clearAllSearch" type="button" class="btn btn-w btn-sm">
        Очистить
      </button>
    </div>
  </div>

  <div v-if="!loadingTable">
    <div v-if="shownData.length">
      <base-table
          v-if="shownData.length"
          :columns="partnerFields"
          :rows="shownData"
          @sort="(data) => {
            sortValue = data.sortValue;
            sortDirection = data.direction;
          }"
      />
      <table-pagination
          v-if="shownData.length"
          :key="updatePagination"
          :totalPages="1"
          :countOnPage="countOnPage"
          :total="totalResults ?? rowData.length"
          :count="countOnPage > shownData.length ? shownData.length : countOnPage"
          @changePage="changePage"
      />
    </div>
    <div v-else class="no_results">
      <p>Нет результатов</p>
    </div>
  </div>
  <div v-else class="table-preloader">
    <preloader/>
  </div>
</template>

<script>
import {reactive, ref} from "@vue/reactivity";
import {useStore} from "vuex";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import Preloader from "@/components/Technical/Preloader.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import CustomSelectFilter from "@/components/Tables/FiltersComponents/CustomSelectFilter.vue";
import sortData from "@/mixins/sortData";
import BaseTable from "@/components/Tables/BaseTable.vue";

export default {
  name: "partners-table",
  components: {
    BaseTable,
    Preloader,
    CustomInput,
    TablePagination,
    CustomSelectFilter,
  },
  setup() {
    const store = useStore();

    const countOnPage = ref(15),
        isPreloader = ref(false),
        searchParams = reactive({
          titleField: "",
          cityField: "",
          userField: "",
        });
    const loadingTable = computed(() => store.state.ui.loadingTable);
    const partners = computed(() => store.state.partners.centers),
        rowData = computed(() => {
          return partners.value.map((partner) => {
            return {
              id: partner.id,
              title: partner.title,
              city: partner.city ? partner.city.name : "",
              edit: partner.id,
              type:
                  partner.type && partner.type === "expert"
                      ? "Экспертный"
                      : "Тренинговый центр",
              new: partner.status,
              image: partner.image,
              user: partner.user.name,
              user_id: partner.user.id,
            };
          });
        });

    const partnerFields = [
      {
        field: "partner_renderer",
        headerName: "",
      },
      {
        field: "image",
        headerName: "Фото",
      },
      {
        field: "title",
        headerName: "Название",
        sortable: true,
        link: true,
        dest: "partner",
      },
      {
        field: "city",
        headerName: "Город",
      },
      {
        field: "user",
        headerName: "Пользователь",
        link: true,
        linkId: "user_id",
        dest: "staff",
        res: "edit",
      },
      {
        field: "edit",
        headerName: "",
        dest: "partner",
      },
    ];

    const pageDataStart = ref(0);
    const sortValue = ref('');
    const sortDirection = ref('');
    const updatePagination = ref(false);
    const totalResults = ref();

    const shownData = computed(() => {
      let data = rowData.value ?? [];

      if (data.length) {

        if (searchParams.cityField && searchParams.cityField.length >= 3) {
          data = data.filter((el) => el.city.toLowerCase().includes(searchParams.cityField.toLowerCase()));
        }

        if (searchParams.titleField && searchParams.titleField.length >= 3) {
          data = data.filter((el) => el.title.toLowerCase().includes(searchParams.titleField.toLowerCase()));
        }

        if (searchParams.userField && searchParams.userField.length >= 3) {
          data = data.filter((el) =>
              el.user.toLowerCase().includes(searchParams.userField.toLowerCase())
          );
        }

        if (sortValue.value) {
          data = sortData(data, sortValue.value, sortDirection.value);
        } else {
          data = sortData(data, 'new', 'down');
        }

        totalResults.value = data.length;
        data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }

      return data;
    });

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    const clearAllSearch = () => {
      searchParams.titleField = "";
      searchParams.userField = "";
      searchParams.cityField = "";
    };

    onBeforeMount(() => {
      store.commit("ui/setLoadingTable", true);
      store
          .dispatch("partners/getCenters")
          .finally(() => store.commit("ui/setLoadingTable", false));
    });

    watch(
        () => [sortDirection.value, sortValue.value, countOnPage.value,
          searchParams.cityField, searchParams.titleField],
        () => {
          totalResults.value = 0;
          pageDataStart.value = 0;
          updatePagination.value = !updatePagination.value;
        },
    )

    return {
      countOnPage,
      isPreloader,
      partners,
      searchParams,
      loadingTable,

      rowData,

      changePage,
      shownData,
      partnerFields,
      totalResults,
      clearAllSearch,
      sortValue,
      sortDirection,
      updatePagination,
    };
  },
};
</script>

<style lang="scss" scoped>
.no_results {
  padding: 40px 10px;
}

.filters {
  display: flex;
  row-gap: 5px;
  flex-direction: column;

}

.filter_block {
  display: flex;
  flex-direction: column;
}

.filter_block .form-group {
  width: 70%;
}

.search_btn {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .filter_block {
    flex-direction: column;
    width: 100%;
  }

  .filter_block .form-group {
    width: 100%;
  }
}
</style>
